<template>
  <div class="club-layout d-flex">
    <section class="content-wrapper">
      <slot />
    </section>
  </div>
</template>

<script>
export default {
  name: 'ClubLayout'
}
</script>

<style lang="scss">
.club-layout {
  position: relative;
  .content-wrapper {
    position: relative;
    width: 100%;
    min-height: 100vh;
    &:before {
      position: absolute;
      content: '';
      background-image: url('../assets/images/background-paper-01.jpg');
      background-size: cover;
      background-position: center center;
      width: 100%;
      height: 100%;
      opacity: .2;
      z-index: -1;
    }
  }
}
</style>
